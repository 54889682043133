import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Flight} from './flight';
import {map, tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FlightService {
    private flightsUrl = 'http://api.flightlog.johnplaxco.com/api/flights';
    private flightList: Flight[] | null;

    getFlights(rocketId = null, filter = '', sortColumn = 'flew_at', sortOrder = 'asc',
        limit = 0, offset = 10):  Observable<Flight[]> {
        return this.http.get<Flight[]>(this.flightsUrl, {
            params: new HttpParams()
                .set('rocket_id', (rocketId&&rocketId>0)?rocketId:'')
                .set('filter', filter)
                .set('sortOrder', sortOrder)
                .set('sortColumn', sortColumn)
                .set('limit', limit.toString())
                .set('offset', offset.toString())
        });
    }

    saveFlight(flight: Flight) {
        return this.http.put<Flight>('http://api.flightlog.johnplaxco.com/api/flights/'+flight.id,
                                     flight);
    }

    createFlight(flight: Flight) {
        return this.http.post<Flight>('http://api.flightlog.johnplaxco.com/api/flights',
                                      flight);
    }

    getFlight(id: number): Observable<Flight> {
        const url = `${this.flightsUrl}/${id}`;
        return this.http.get<Flight>(url);
    }

    constructor(private http: HttpClient) {}
}

import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

export class FlightDataSource extends DataSource<Flight> {

    private flightSubject = new BehaviorSubject<Flight[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    
    constructor(private flightService: FlightService) {
        super();
    }

    connect(): Observable<Flight[]> {
        return this.flightSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.flightSubject.complete();
        this.loadingSubject.complete();
    }

    loadFlights(rocketId: number = null, filter: string = '', sortColumn: string = 'flew_at',
                sortDirection: string = 'asc', limit: number = 10, offset: number = 0) {
        let me = this;
        this.loadingSubject.next(true);
        this.flightService.getFlights(rocketId, filter, sortColumn, sortDirection,
                                        limit, offset)
            .subscribe({
                next(position) { me.flightSubject.next(position); me.loadingSubject.next(false); }
            });
    }
}
