import {Component, OnInit, Input, ViewChild, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {Flight} from '../flight';
import {MatSort, MatPaginator} from '@angular/material';
import {FlightService, FlightDataSource} from '../flight.service';
import { fromEvent, BehaviorSubject } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged, startWith, delay } from 'rxjs/operators';

@Component({
  selector: 'app-flight-list',
  templateUrl: './flight-list.component.html',
  styleUrls: ['./flight-list.component.css']
})
export class FlightListComponent implements AfterViewInit, OnInit {
    flights: Flight[];
    displayedColumns: string[] = ['flew_at', 'rocket', 'engine', 'altitude_m'];
    dataSource: FlightDataSource | null;
    isLoadingResults = false;
    private flightCountSubject = new BehaviorSubject<number>(0);
    @Input() rocketId: number = null;
    public flightCount$ = this.flightCountSubject.asObservable();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('flightFilter') input;
    
    constructor(private flightService: FlightService, private router:Router) {
    }
    
    onSelectRow(flight: Flight) {
        this.router.navigateByUrl('/flight/'+flight.id);
    }

    onCreateNew() {
        if (this.rocketId && this.rocketId > 0) {
            this.router.navigateByUrl('/flight/-1/rocket/'+this.rocketId);
        } else {
            this.router.navigateByUrl('/flight/-1');
        }
    }

    ngOnInit() {
        if (this.rocketId && this.rocketId > 0) {
            this.displayedColumns = ['flew_at', 'engine', 'altitude_m'];
        }
        let me = this;
        this.dataSource = new FlightDataSource(this.flightService);
        this.dataSource.connect().subscribe({next(p) {me.flightsLoaded(p);}});
    }

    flightsLoaded(n) {
        if (n.length) {
            this.flightCountSubject.next(n[0].full_count);
        } else {
            this.flightCountSubject.next(0);
        }
    }

    ngAfterViewInit() {
        fromEvent(this.input.nativeElement, 'keyup').pipe(
            debounceTime(150),
            distinctUntilChanged(),
            tap(() => {
                this.paginator.pageIndex = 0;
                this.getFlights();
            })
        ).subscribe();

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        this.paginator.page
            .pipe(
                startWith(null),
                delay(0),
                tap(() => this.getFlights())
            )
            .subscribe();
        this.sort.sortChange
            .pipe(
                tap(() => this.getFlights())
            )
            .subscribe();
    }

    getFlights() {
        this.dataSource.loadFlights(this.rocketId, this.input.nativeElement.value,
                                    this.sort.active,
                                    this.sort.direction,
                                    this.paginator.pageSize,
                                    this.paginator.pageIndex * this.paginator.pageSize);
    }
}
