import {Component} from '@angular/core';

@Component({
    selector: 'app-spinner-overlay',
    templateUrl: 'spinner-overlay.component.html',
    styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent {
    constructor() {
    }
}
