import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {Rocket} from '../rocket';
import {MatSort, MatPaginator} from '@angular/material';
import {RocketService, RocketDataSource} from '../rocket.service';
import { fromEvent, BehaviorSubject } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged, startWith, delay } from 'rxjs/operators';

@Component({
  selector: 'app-rocket-list',
  templateUrl: './rocket-list.component.html',
  styleUrls: ['./rocket-list.component.css']
})
export class RocketListComponent implements AfterViewInit, OnInit {
    rockets: Rocket[];
    displayedColumns: string[] = ['public', 'name', 'designer', 'mass_g'];
    dataSource: RocketDataSource | null;
    isLoadingResults = false;
    private rocketCountSubject = new BehaviorSubject<number>(0);
    public rocketCount$ = this.rocketCountSubject.asObservable();
    
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('rocketFilter') input;
    
    constructor(private rocketService: RocketService, private router:Router) {}
    
    onSelectRow(rocket: Rocket) {
        this.router.navigateByUrl('/rocket/'+rocket.id);
    }

    onCreateNew() {
        this.router.navigateByUrl('/rocket/-1');
    }

    ngOnInit() {
        let me = this;
        this.dataSource = new RocketDataSource(this.rocketService);
        this.dataSource.connect().subscribe({next(p) {me.rocketsLoaded(p);}});
    }

    rocketsLoaded(n) {
        if (n.length) {
            this.rocketCountSubject.next(n[0].full_count);
        } else {
            this.rocketCountSubject.next(0);
        }
    }

    ngAfterViewInit() {
        fromEvent(this.input.nativeElement, 'keyup').pipe(
            debounceTime(150),
            distinctUntilChanged(),
            tap(() => {
                this.paginator.pageIndex = 0;
                this.getRockets();
            })
        ).subscribe();

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        this.paginator.page
            .pipe(
                startWith(null),
                delay(0),
                tap(() => this.getRockets())
            )
            .subscribe();
        this.sort.sortChange
            .pipe(
                tap(() => this.getRockets())
            )
            .subscribe();
    }

    getRockets() {
        this.dataSource.loadRockets(this.input.nativeElement.value,
                                    this.sort.active,
                                    this.sort.direction,
                                    this.paginator.pageSize,
                                    this.paginator.pageIndex * this.paginator.pageSize);
    }
}
