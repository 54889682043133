import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationService} from '../_services/authentication.service';

@Component({
  selector: 'app-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrls: ['./mainmenu.component.css']
})
export class MainmenuComponent implements OnInit {
    
    constructor(private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit() {
  }

    doLogout() {
        this.authenticationService.logout();
        this.router.navigate(['']);
    }

}
