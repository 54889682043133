import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

class User {
    email: string;
    password: string;
    token: string;
}
@Component({
  selector: 'app-resetpw',
  templateUrl: './resetpw.component.html',
  styleUrls: ['./resetpw.component.css']
})
export class ResetPWComponent implements OnInit {
    @Input() user: User = {email:'',password:'',token:''}
    errMsg: BehaviorSubject<string> = new BehaviorSubject<string>('');
    doingResetpwSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    
    constructor(private authService: AuthenticationService,
                private router: Router,
                private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.queryParams
            .subscribe(params => {
                this.user.token = params['token'] || '';
                this.user.email = params['email'] || ''
            });
    }

    onChangePW() {
        let me = this;
        this.doingResetpwSubject.next(true);
        this.errMsg.next('');

        this.authService.resetpw(this.user.email, this.user.token, this.user.password)
            .pipe(
                catchError(e => {
                    if (e.error.error) {
                        this.errMsg.next(e.error.error);
                    } else {
                        this.errMsg.next('Unable to reset password.');
                    }
                    return throwError(e);
                })
            )
            .subscribe({
                next(f) {
                    me.doingResetpwSubject.next(false);
                    me.router.navigateByUrl('/login');
                },
                error(f) { me.doingResetpwSubject.next(false); }
            });
    }

}
