import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Rocket} from './rocket';
import {Engine} from './engine';
import {catchError, map, tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RocketService {
    private rocketsUrl = 'http://api.flightlog.johnplaxco.com/api/rockets';
    private rocketList: Rocket[] | null;

    getRockets(filter = '', sortColumn = 'name', sortOrder = 'asc',
        limit = 0, offset = 0):  Observable<Rocket[]> {

        return this.http.get<Rocket[]>(this.rocketsUrl, {
            params: new HttpParams()
                .set('filter', filter)
                .set('sortOrder', sortOrder)
                .set('sortColumn', sortColumn)
                .set('limit', limit.toString())
                .set('offset', offset.toString())
        });
    }

    saveRocket(rocket: Rocket) {
        return this.http.put<Rocket>(this.rocketsUrl+'/'+rocket.id,
                                     rocket);
    }

    createRocket(rocket: Rocket) {
        return this.http.post<Rocket>(this.rocketsUrl,
                                      rocket);
    }

    getRocket(id: number): Observable<Rocket> {
        const url = `${this.rocketsUrl}/${id}`;
        return this.http.get<Rocket>(url);
    }

    getEnginesForRocket(id: number):  Observable<Engine[]> {
        return this.http.get<Engine[]>(this.rocketsUrl+'/'+id+'/engines', {
            params: new HttpParams()
        });
    }

    setEnginesForRocket(id: number, engineIDs: number[]) {
        return this.http.post(this.rocketsUrl+'/'+id+'/engines', {
            'engine_ids': engineIDs
        });
    }

    constructor(private http: HttpClient) {}
}

import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

export class RocketDataSource extends DataSource<Rocket> {

    private rocketSubject = new BehaviorSubject<Rocket[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    
    constructor(private rocketService: RocketService) {
        super();
    }

    connect(): Observable<Rocket[]> {
        return this.rocketSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.rocketSubject.complete();
        this.loadingSubject.complete();
    }

    loadRockets(filter: string = '', sortColumn: string = 'name',
                sortDirection: string = 'asc', limit: number = 0, offset: number = 0) {
        let me = this;
        this.loadingSubject.next(true);
        this.rocketService.getRockets(filter, sortColumn, sortDirection,
                                      limit, offset)
            .subscribe({
                next(position) { me.rocketSubject.next(position); me.loadingSubject.next(false); }
            });
    }
}
