import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule, MatDatepickerModule,MatNativeDateModule, MatIconModule, MatToolbarModule, MatButtonModule, MatListModule, MatTableModule, MatProgressSpinnerModule, MatSortModule, MatPaginatorModule, MatInputModule, MatCheckboxModule, MatTooltipModule, MatSlideToggleModule, MatSelectModule, MatTabsModule, MatGridListModule, MatDividerModule} from '@angular/material';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { FlexLayoutModule } from '@angular/flex-layout';
import {AppComponent} from './app.component';
import {RocketComponent} from './rocket/rocket.component';
import {EngineComponent} from './engine/engine.component';
import {FlightComponent} from './flight/flight.component';
import {RocketListComponent} from './rocket-list/rocket-list.component';
import {FlightListComponent} from './flight-list/flight-list.component';
import {EngineListComponent} from './engine-list/engine-list.component';
import {EngineMultiselectComponent} from './engine-multiselect/engine-multiselect.component';
import {MainmenuComponent} from './mainmenu/mainmenu.component';
import {MessagesComponent} from './messages/messages.component';
import {AppRoutingModule} from './/app-routing.module';
import {DashboardComponent} from './dashboard/dashboard.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { LoginComponent } from './login/login.component';
import { ResetPWComponent } from './login/resetpw.component';
import { UiCommonModule } from './ui-common/ui-common.module';

@NgModule({
  declarations: [
      AppComponent,
      RocketComponent,
      EngineComponent,
      FlightComponent,
      RocketListComponent,
      FlightListComponent,
      EngineListComponent,
      MainmenuComponent,
      MessagesComponent,
      DashboardComponent,
      LoginComponent,
      ResetPWComponent,
      EngineMultiselectComponent
  ],
    imports: [
        UiCommonModule,
      BrowserModule, FormsModule, ReactiveFormsModule,
      MatToolbarModule, AppRoutingModule, MatButtonModule, MatListModule, MatTableModule, MatProgressSpinnerModule, MatIconModule, MatSortModule, MatPaginatorModule, MatInputModule, MatCheckboxModule, MatTooltipModule,MatSlideToggleModule, MatDatepickerModule,MatNativeDateModule,MatTabsModule,MatGridListModule,
      HttpClientModule, NgxMaterialTimepickerModule,MatSelectModule,
      BrowserAnimationsModule,FlexLayoutModule, MatCardModule, MatDividerModule
  ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
  bootstrap: [AppComponent]
})
export class AppModule {}
