import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Engine} from './engine';
import {map, tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EngineService {
    private enginesUrl = 'http://api.flightlog.johnplaxco.com/api/engines';
    private engineList: Engine[] | null;

    getEngines(rocketId = null, filter = '', sortColumn = 'impulse_nsec', sortOrder = 'asc',
               limit = 0, offset = 0):  Observable<Engine[]> {
        return this.http.get<Engine[]>(this.enginesUrl, {
            params: new HttpParams()
                .set('rocket_id', (rocketId&&rocketId>0)?rocketId:'')
                .set('filter', filter)
                .set('sortOrder', sortOrder)
                .set('sortColumn', sortColumn)
                .set('limit', limit.toString())
                .set('offset', offset.toString())
        });
    }

    saveEngine(engine: Engine) {
        return this.http.put<Engine>(this.enginesUrl+'/'+engine.id,
                                     engine);
    }

    createEngine(engine: Engine) {
        return this.http.post<Engine>(this.enginesUrl,
                                      engine);
    }

    getEngine(id: number): Observable<Engine> {
        const url = `${this.enginesUrl}/${id}`;
        return this.http.get<Engine>(url);
    }

    constructor(private http: HttpClient) {}
}

import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

export class EngineDataSource extends DataSource<Engine> {

    private engineSubject = new BehaviorSubject<Engine[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    
    constructor(private engineService: EngineService) {
        super();
    }

    connect(): Observable<Engine[]> {
        return this.engineSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.engineSubject.complete();
        this.loadingSubject.complete();
    }

    loadEngines(rocketId: number = null, filter: string = '', sortColumn: string = 'impulse_nsec',
                sortDirection: string = 'asc', limit: number = 0, offset: number = 0) {
        let me = this;
        this.loadingSubject.next(true);
        this.engineService.getEngines(rocketId, filter, sortColumn, sortDirection,
                                      limit, offset)
            .subscribe({
                next(position) { me.engineSubject.next(position); me.loadingSubject.next(false);}
            });
    }
}
