import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import { Rocket } from '../rocket';
import { Flight } from '../flight'
import { Engine } from '../engine'
import { ActivatedRoute, Router } from '@angular/router';
import { RocketService }  from '../rocket.service';
import { FlightService }  from '../flight.service';
import { EngineService }  from '../engine.service';
import { BehaviorSubject } from 'rxjs';
import { GeneralDialogComponent } from '../ui-common/general-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-flight',
  templateUrl: './flight.component.html',
  styleUrls: ['./flight.component.scss']
})
export class FlightComponent implements OnInit {
    flight: Flight = {
        id: -1,
        crud: {},
        engine_id: -1,
        user_id: -1,
        rocket_id: -1,
        altitude_m: 0,
        flew_at: new Date(),
        conditions: '',
        notes: '',
        recovered: true,
        created_at: new Date(),
        updated_at: new Date(),
    }
    rockets: Rocket[] = [];
    engines: Engine[] = [];
    rocketsById: Rocket[] = [];
    enginesById: Engine[] = [];
    selectedRocketId: number = -1;
    selectedEngineId: number = -1;
    public isEditable = true;
    public dataLoading = new BehaviorSubject<boolean>(true);
    isExistingFlight: BehaviorSubject<boolean> = new BehaviorSubject(false);
    flew_at_date: FormControl = new FormControl(null);
    flew_at_time: FormControl = new FormControl(null);

    constructor(private dialog: MatDialog,
                private route: ActivatedRoute,
                private router: Router,
                private engineService: EngineService,
                private flightService: FlightService,
                private rocketService: RocketService) {
    }

    ngOnInit() {
        this.flight.id = parseInt(this.route.snapshot.paramMap.get('id'));
        if (!this.flight.id) {
            this.flight.id = -1;
        }

        if (this.route.snapshot.paramMap.get('rocketId')) {
            this.selectedRocketId = this.flight.rocket_id = parseInt(this.route.snapshot.paramMap.get('rocketId'));
        }
        this.isExistingFlight.next(this.flight.id?this.flight.id>0:false);
    }

    ngAfterViewInit() {
        this.getFlight();
    }

    getFlight(): void {
        let me = this;
        const id = this.flight.id;
        if (id > 0) {
            this.flightService.getFlight(id)
                .subscribe(flight => {
                    this.flight = flight;
                    this.selectedRocketId = this.flight.rocket_id;
                    this.selectedEngineId = this.flight.engine_id;
                    let d = new Date(flight.flew_at);
                    this.flew_at_date = new FormControl(d);
                    this.flew_at_time = new FormControl(d.getHours()+':'+d.getMinutes());
                    this.isEditable = this.flight.crud['u'];
                    this.dataLoading.next(false);
                });
        }
        
        this.rocketService.getRockets().subscribe(rockets => {
            this.rockets = rockets;
            rockets.forEach(r=>{
                this.rocketsById[r.id] = r;
            });
            this.selectedRocketId = this.flight.rocket_id;
        });
        this.engineService.getEngines().subscribe(engines => {
            this.engines = engines;
            engines.forEach(e=>{
                this.enginesById[e.id] = e;
            });
            this.selectedEngineId = this.flight.engine_id;
        });
    }

    onSave(): void {
        this.dataLoading.next(true);
        let me = this;
        let d = this.flew_at_date.value;
        d.setHours(this.flew_at_time.value.split(":")[0], this.flew_at_time.value.split(":")[1]);
        this.flight.flew_at = d;
        this.flight.rocket_id = this.selectedRocketId;
        this.flight.engine_id = this.selectedEngineId;

        if (this.flight.id > 0) {
            this.flightService.saveFlight(this.flight).subscribe(f => {
                me.flight = f;
                let dnew = new Date(me.flight.flew_at);
                me.flew_at_date = new FormControl(dnew);
                me.flew_at_time = new FormControl(dnew.getHours()+':'+dnew.getMinutes());
                me.isExistingFlight.next(true);
                me.flight.updated_at = new Date();
                this.dataLoading.next(false);
                const saveDialog = this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'check_circle', title:'Save Successful', message: 'Flight created successfully.' }});
                saveDialog.afterClosed().subscribe(answer => {
                    me.router.navigateByUrl('/flight/'+f.id);
                });
            },
            error => {
                this.dataLoading.next(false);
                this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'error', title:'Error Saving', message: 'Encounered an error while creating flight'+(error.error.message?': '+error.error.message:'.') }});
            });
        } else {
            this.flightService.createFlight(this.flight).subscribe(f => {
                me.flight = f;
                let dnew = new Date(me.flight.flew_at);
                me.flew_at_date = new FormControl(dnew);
                me.flew_at_time = new FormControl(dnew.getHours()+':'+dnew.getMinutes());
                me.isExistingFlight.next(true);
                me.flight.updated_at = new Date();
                this.dataLoading.next(false);
                const saveDialog = this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'check_circle', title:'Save Successful', message: 'Rocket saved successfully.' }});
                saveDialog.afterClosed().subscribe(answer => {
                    me.router.navigateByUrl('/flight/'+f.id);
                });
            },
            error => {
                this.dataLoading.next(false);
                this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'error', title:'Error Saving', message: 'Encounered an error while saving flight'+(error.error.message?': '+error.error.message:'.') }});
            });

        }
    }
}
