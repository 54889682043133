import {Component, OnInit, Input, ViewChild, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {Engine} from '../engine';
import {MatSort, MatPaginator, MatTableDataSource} from '@angular/material';
import {EngineService, EngineDataSource} from '../engine.service';
import {RocketService} from '../rocket.service';
import { fromEvent, BehaviorSubject } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged, delay, startWith } from 'rxjs/operators';

class CheckableEngine extends Engine {
    checked: boolean = false;
    full_count: number;
}

@Component({
  selector: 'app-engine-multiselect',
  templateUrl: './engine-multiselect.component.html',
  styleUrls: ['./engine-multiselect.component.scss']
})
export class EngineMultiselectComponent implements AfterViewInit, OnInit {
    displayedColumns: string[] = ['checked','name','manufacturer','max_lift_g', 'diameter_mm', 'length_mm'];
    dataSource: MatTableDataSource<CheckableEngine>;
    private engineCountSubject = new BehaviorSubject<number>(0);
    @Input() rocketId: number = null;
    @Input() disabled: boolean = false;
    public engineCount$ = this.engineCountSubject.asObservable();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('engineFilter') input;
    public compatibleEngines: Engine[] = [];
    public loading = new BehaviorSubject<boolean>(true);
    public compatloading = new BehaviorSubject<boolean>(true);
    public dirty: boolean = false;
    
    constructor(private engineService: EngineService,
                private rocketService: RocketService,
                private router:Router) {
    }
    
    ngOnInit() {
    }

    ngAfterViewInit() {
        fromEvent(this.input.nativeElement, 'keyup').pipe(
            debounceTime(150),
            distinctUntilChanged(),
            tap(() => {
                this.paginator.pageIndex = 0;
                this.getEngines();
            })
        ).subscribe();

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        this.paginator.page
            .pipe(
                startWith(null),
                delay(0),
                tap(() => this.getEngines())
            )
            .subscribe();
        this.sort.sortChange
            .pipe(
                tap(() => this.getEngines())
            )
            .subscribe();
    }

    public updateChecked() {
        this.dataSource.data.forEach(e => {
            console.log('checked?', e.checked);
            const index = this.compatibleEngines.findIndex(x => e.id===x.id);
            if (e.checked && -1 === index) {
                console.log('pushed');
                this.compatibleEngines.push(e);
                this.dirty = true;
            } else if (!e.checked && -1 !== index) {
                console.log('unpushed');
                this.compatibleEngines.splice(index, 1);
                this.dirty = true;
            }
        });
    }

    private getCompatibleEngines() {
        if (this.compatibleEngines.length) {
            const oldEngines = this.dataSource.data;
            oldEngines.forEach(oe => {
                oe.checked = false;
                this.compatibleEngines.forEach(e=>{
                    if (e.id === oe.id) {
                        oe.checked = true;
                    }
                })});
            this.dataSource.data = oldEngines;
            return;
        }
        this.compatloading.next(true);
        this.rocketService.getEnginesForRocket(this.rocketId)
            .subscribe(engines=> {
                const oldEngines = this.dataSource.data;
                oldEngines.forEach(oe => {
                    oe.checked = false;
                    engines.forEach(e=>{
                        if (e.id === oe.id) {
                            oe.checked = true;
                        }
                    })});
                this.dataSource.data = oldEngines;
                this.compatibleEngines = engines;
                this.compatloading.next(false);
            });
    }

    private getEngines() {
        this.loading.next(true);
        this.engineService.getEngines(this.rocketId, this.input.nativeElement.value,
                                      this.sort.active,
                                      this.sort.direction,
                                      this.paginator.pageSize,
                                      this.paginator.pageIndex * this.paginator.pageSize)
            .subscribe(engines=> {
                this.dataSource = new MatTableDataSource(engines as CheckableEngine[]);
                this.dataSource.sort = this.sort;
                if (this.dataSource.data && this.dataSource.data.length) {
                    this.engineCountSubject.next(this.dataSource.data[0].full_count);
                } else {
                    this.engineCountSubject.next(0);
                }
                this.getCompatibleEngines();
                this.loading.next(false);
            });
    }
}
