import { Injectable } from '@angular/core';
import {Observable, of, BehaviorSubject} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private loginSubject = new BehaviorSubject<boolean>(false);
    public isLoggedIn$ = this.loginSubject.asObservable();
    
    constructor(private http: HttpClient) {
        if (JSON.parse(localStorage.getItem('currentUser'))) {
            this.loginSubject.next(true);
        }
    }

    login(username: string, password: string): Observable<any> {
        return this.http.post<any>('http://api.flightlog.johnplaxco.com/api/login', { email: username, password: password })
            .pipe(map((res:any) => {
                // login successful if there's a jwt token in the response
                if (res && res.data && res.data.token) {
                    // store username and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify({ token: res.data.token }));
                    this.loginSubject.next(true);
                }
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.loginSubject.next(false);
    }

    register(name: string, username: string, password: string): Observable<any> {
        return this.http.post<any>('http://api.flightlog.johnplaxco.com/api/register', { name: name, email: username, password: password })
            .pipe(map((res:any) => {
                this.loginSubject.next(true);
                return res;
            }));
    }

    verify(token: string): Observable<any> {
        return this.http.post<any>('http://api.flightlog.johnplaxco.com/api/verify',{token:token})
            .pipe(map((res:any) => {
                this.loginSubject.next(true);
                return res;
            }));
    }

    startPWReset(username: string): Observable<any> {
        return this.http.post<any>('http://api.flightlog.johnplaxco.com/api/recover', { email: username })
            .pipe(map((res:any) => {
                console.log('in the first map');
                this.loginSubject.next(true);
                return res;
            }));
    }

    resetpw(username: string, token: string, newpw: string): Observable<any> {
        return this.http.post<any>('http://api.flightlog.johnplaxco.com/api/resetpw', { email: username, token: token, password: newpw, password_confirmation: newpw })
            .pipe(map((res:any) => {
                this.loginSubject.next(true);
                return res;
            }));
    }
}
