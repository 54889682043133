import {Component, Inject} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
    selector: 'app-general-dialog',
    templateUrl: 'general-dialog.component.html',
    styleUrls: ['./general-dialog.component.scss']
})
export class GeneralDialogComponent {
    public showSave: boolean;
    public showDelete: boolean;
    public showDiscard: boolean;
    public showOK: boolean;
    public showCancel: boolean;
    public title: string;
    public icon: string;
    public message: string;

    constructor(private dialogRef: MatDialogRef<GeneralDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.showSave = !!data.showSave;
        this.showDelete = !!data.showDelete;
        this.showDiscard = !!data.showDiscard;
        this.showOK = !!data.showOK;
        this.showCancel = !!data.showCancel;

        this.title = data.title?data.title: 'No title defined';
        this.icon = data.icon?data.icon: 'error';
        this.message = data.message?data.message: 'No message defined';
    }
}
