import {Component, OnInit, Input, ViewChild, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {Engine} from '../engine';
import {MatSort, MatPaginator} from '@angular/material';
import {EngineService, EngineDataSource} from '../engine.service';
import { fromEvent, BehaviorSubject } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged, delay, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-engine-list',
  templateUrl: './engine-list.component.html',
  styleUrls: ['./engine-list.component.css']
})
export class EngineListComponent implements AfterViewInit, OnInit {
    engines: Engine[];
    displayedColumns: string[] = ['name','manufacturer','impulse_nsec', 'delay_s', 'max_lift_g', 'max_thrust_n', 'thrust_duration_s', 'casing_g', 'propellant_g', 'diameter_mm', 'length_mm'];
    
    dataSource: EngineDataSource | null;
    isLoadingResults = false;
    private engineCountSubject = new BehaviorSubject<number>(0);
    @Input() rocketId: number = null;
    public engineCount$ = this.engineCountSubject.asObservable();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('engineFilter') input;
    
    constructor(private engineService: EngineService, private router:Router) {
    }
    
    onSelectRow(engine: Engine) {
        this.router.navigateByUrl('/engine/'+engine.id);
    }

    onCreateNew() {
        this.router.navigateByUrl('/engine/-1');
    }

    ngOnInit() {
        let me = this;
        this.dataSource = new EngineDataSource(this.engineService);
        this.dataSource.connect().subscribe({next(p) {me.enginesLoaded(p);}});
    }

    enginesLoaded(n) {
        if (n.length) {
            this.engineCountSubject.next(n[0].full_count);
        } else {
            this.engineCountSubject.next(0);
        }
    }

    ngAfterViewInit() {
        fromEvent(this.input.nativeElement, 'keyup').pipe(
            debounceTime(150),
            distinctUntilChanged(),
            tap(() => {
                this.paginator.pageIndex = 0;
                this.getEngines();
            })
        ).subscribe();

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        this.paginator.page
            .pipe(
                startWith(null),
                delay(0),
                tap(() => this.getEngines())
            )
            .subscribe();
        this.sort.sortChange
            .pipe(
                tap(() => this.getEngines())
            )
            .subscribe();
    }

    getEngines() {
        this.dataSource.loadEngines(this.rocketId, this.input.nativeElement.value,
                                    this.sort.active,
                                    this.sort.direction,
                                    this.paginator.pageSize,
                                    this.paginator.pageIndex * this.paginator.pageSize);
    }
}
