import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Engine } from '../engine';
import { ActivatedRoute, Router } from '@angular/router';
import { EngineService }  from '../engine.service';
import { BehaviorSubject } from 'rxjs';
import { GeneralDialogComponent } from '../ui-common/general-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html',
  styleUrls: ['./engine.component.scss']
})
export class EngineComponent implements OnInit {
    @Input() engine: Engine = {
        id: -1,
        user_id: -1,
        public: false,
        crud: {},
        impulse_nsec: null,
        delay_s: null,
        max_lift_g: null,
        max_thrust_n: null,
        thrust_duration_s: null,
        casing_g: null,
        propellant_g: null,
        diameter_mm: null,
        length_mm: null,
        manufacturer: null,
        notes: null,
        created_at: new Date(),
        updated_at: new Date()
    };
    public dataLoading = new BehaviorSubject<boolean>(true);
    public isEditable: boolean = true;
    isExistingEngine: BehaviorSubject<boolean> = new BehaviorSubject(false);
    
    constructor(private dialog: MatDialog,
                private route: ActivatedRoute,
                private router: Router,
                private engineService: EngineService) {
    }

    onSave() {
        this.dataLoading.next(true);
        let me = this;
        if (this.engine.id == -1) {
            this.engineService.createEngine(this.engine).subscribe(f => {
                me.engine = f;
                me.isExistingEngine.next(true);
                this.dataLoading.next(false);
                const saveDialog = this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'check_circle', title:'Save Successful', message: 'Engine created successfully.' }});
                saveDialog.afterClosed().subscribe(answer => {
                    me.router.navigateByUrl('/engine/'+f.id);
                });
            },
            error => {
                this.dataLoading.next(false);
                this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'error', title:'Error Saving', message: 'Encounered an error while creating engine'+(error.error.message?': '+error.error.message:'.') }});
            });
        } else {
            this.engineService.saveEngine(this.engine).subscribe(f => {
                me.engine = f;
                me.isExistingEngine.next(true);
                this.dataLoading.next(false);
                const saveDialog = this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'check_circle', title:'Save Successful', message: 'Engine saved successfully.' }});
                saveDialog.afterClosed().subscribe(answer => {
                    me.router.navigateByUrl('/engine/'+f.id);
                });
            },
            error => {
                this.dataLoading.next(false);
                this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'error', title:'Error Saving', message: 'Encounered an error while saving engine'+(error.error.message?': '+error.error.message:'.') }});
            });
        }
        
    }
    
    ngOnInit() {
        this.engine.id = parseInt(this.route.snapshot.paramMap.get('id'));
        this.isExistingEngine.next(this.route.snapshot.paramMap.get('id') != '-1');
        this.isEditable = true;
        if (this.engine.id <= 0) {
            this.dataLoading.next(false);
        }
    }

    ngAfterViewInit() {
        this.getEngine();
    }

    getEngine(): void {
        this.isEditable = true;
        if (this.engine.id > 0) {
            this.engineService.getEngine(this.engine.id)
                .subscribe(engine => {
                    this.engine = engine;
                    if (!this.engine.crud['u']) {
                        this.isEditable = false;
                    }
                    this.dataLoading.next(false);
                });
        }
    }
    
}
