import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
    MatListModule, MatDialogModule, MatButtonModule, MatProgressSpinnerModule, MatSelectModule, MatDatepickerModule,
    MatInputModule, MatFormFieldModule, MatCardModule, MatTableModule, MatSortModule, MatCheckboxModule, MatIconModule,
    MatTooltipModule
} from '@angular/material';
import { GeneralDialogComponent } from './general-dialog.component';
import { SpinnerOverlayComponent } from './spinner-overlay.component';

@NgModule({
    declarations: [
        GeneralDialogComponent,
        SpinnerOverlayComponent
    ],
    exports: [
        MatListModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatDatepickerModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCardModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        FlexLayoutModule,
        MatIconModule,
        MatTooltipModule,
        GeneralDialogComponent,
        SpinnerOverlayComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatListModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatDatepickerModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCardModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        FlexLayoutModule,
        MatIconModule,
        MatTooltipModule,
        RouterModule
    ],
    entryComponents: [
        GeneralDialogComponent
    ]
})
export class UiCommonModule { }
