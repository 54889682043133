export class Engine {
    id: number;
    user_id: number;
    public: boolean;
    crud: object;
    impulse_nsec: number;
    delay_s: number;
    max_lift_g: number;
    max_thrust_n: number;
    thrust_duration_s: number;
    casing_g: number;
    propellant_g: number;
    diameter_mm: number;
    length_mm: number;
    manufacturer: string;
    notes: string;
    created_at: Date;
    updated_at: Date;
    
}
