import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RocketListComponent }      from './rocket-list/rocket-list.component';
import { FlightListComponent }      from './flight-list/flight-list.component';
import { EngineListComponent }      from './engine-list/engine-list.component';
import { DashboardComponent }      from './dashboard/dashboard.component';
import { RocketComponent } from './rocket/rocket.component';
import { EngineComponent } from './engine/engine.component';
import { FlightComponent } from './flight/flight.component';
import { AuthGuard } from './_guards';
import { LoginComponent } from './login/login.component';
import { ResetPWComponent } from './login/resetpw.component';

const routes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'rockets', component: RocketListComponent, canActivate: [AuthGuard] },
    { path: 'flights', component: FlightListComponent, canActivate: [AuthGuard] },
    { path: 'engines', component: EngineListComponent, canActivate: [AuthGuard] },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'rocket/:id', component: RocketComponent, canActivate: [AuthGuard] },
    { path: 'engine/:id', component: EngineComponent, canActivate: [AuthGuard] },
    { path: 'flight/:id/rocket/:rocketId', component: FlightComponent, canActivate: [AuthGuard] },
    { path: 'flight/:id', component: FlightComponent, canActivate: [AuthGuard] },
    { path: 'login/:verificationCode', component: LoginComponent },
    { path: 'login', component: LoginComponent },
    { path: 'resetpw', component: ResetPWComponent },
    { path: '**', redirectTo: '' }

];

@NgModule({
    imports: [ RouterModule.forRoot(routes) ],//, { enableTracing: true }) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule { }
