import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Rocket } from '../rocket';
import { ActivatedRoute, Router } from '@angular/router';
import { RocketService }  from '../rocket.service';
import { FlightListComponent } from '../flight-list/flight-list.component';
import { BehaviorSubject } from 'rxjs';
import { GeneralDialogComponent } from '../ui-common/general-dialog.component';
import { MatDialog } from '@angular/material';
import { EngineMultiselectComponent } from '../engine-multiselect/engine-multiselect.component';

@Component({
  selector: 'app-rocket',
  templateUrl: './rocket.component.html',
  styleUrls: ['./rocket.component.scss']
})
export class RocketComponent implements OnInit {
    @Input() rocket: Rocket = {
        id: -1,
        name: 'New Rocket',
        public: false,
        crud: {},
        designer: 'FIXME: populate name from profile',
        mass_g: 1,
        created_at: new Date(),
        updated_at: new Date(),
        description: '',
        notes: '',
        diameter_mm: 10,
        length_mm: 10,
        max_altitude_m: 10,
        user_id: -1
    };
    @ViewChild(FlightListComponent) flightList: FlightListComponent;
    @ViewChild(EngineMultiselectComponent) engineMultiselect: EngineMultiselectComponent;
    public dataLoading = new BehaviorSubject<boolean>(true);
    public isEditable: boolean = true;
    isExistingRocket: BehaviorSubject<boolean> = new BehaviorSubject(false);
    
    constructor(private dialog: MatDialog,
                private route: ActivatedRoute,
                private router: Router,
                private rocketService: RocketService) {
    }

    onSave() {
        console.log(this.engineMultiselect.compatibleEngines);
        this.dataLoading.next(true);
        let me = this;
        if (this.rocket.id == -1) {
            this.rocketService.createRocket(this.rocket).subscribe(f => {
                me.rocket = f;
                me.isExistingRocket.next(true);
                this.dataLoading.next(false);
                const saveDialog = this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'check_circle', title:'Save Successful', message: 'Rocket created successfully.' }});
                saveDialog.afterClosed().subscribe(answer => {
                    me.router.navigateByUrl('/rocket/'+f.id);
                });
            },
            error => {
                this.dataLoading.next(false);
                this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'error', title:'Error Saving', message: 'Encounered an error while creating rocket'+(error.error.message?': '+error.error.message:'.') }});
            });
        } else {
            this.rocketService.saveRocket(this.rocket).subscribe(f => {
                me.rocket = f;
                me.isExistingRocket.next(true);
                this.dataLoading.next(false);
                if (!this.saveEngines()) {
                    const saveDialog = this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'check_circle', title:'Save Successful', message: 'Rocket saved successfully.' }});
                    saveDialog.afterClosed().subscribe(answer => {
                        me.router.navigateByUrl('/rocket/'+f.id);
                    });
                }
            },
            error => {
                this.dataLoading.next(false);
                this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'error', title:'Error Saving', message: 'Encounered an error while saving rocket'+(error.error.message?': '+error.error.message:'.') }});
            });
        }
    }

    saveEngines() {
        const me = this;
        if (this.engineMultiselect.dirty) {
            this.dataLoading.next(true);
            const ids = this.engineMultiselect.compatibleEngines.map(e=>e.id);
            this.rocketService.setEnginesForRocket(this.rocket.id, ids).subscribe(f => {
                this.engineMultiselect.dirty = false;
                this.dataLoading.next(false);
                const saveDialog = this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'check_circle', title:'Save Successful', message: 'Rocket saved successfully.' }});
                saveDialog.afterClosed().subscribe(answer => {
                    me.router.navigateByUrl('/rocket/'+me.rocket.id);
                });
            },
            error => {
                this.dataLoading.next(false);
                this.dialog.open(GeneralDialogComponent, { data: { showOK:true, icon:'error', title:'Error Saving', message: 'Encounered an error while saving rocket'+(error.error.message?': '+error.error.message:'.') }});
            });
            return true;
        }
        return false;
    }
    
    ngOnInit() {
        this.rocket.id = parseInt(this.route.snapshot.paramMap.get('id'));
        this.isExistingRocket.next(this.route.snapshot.paramMap.get('id') != '-1');
    }

    ngAfterViewInit() {
        this.getRocket();
    }


    getRocket(): void {
        this.isEditable = true;
        const id = Number(this.route.snapshot.paramMap.get('id'));
        if (id > 0) {
            this.rocketService.getRocket(id)
                .subscribe(rocket => {
                    this.rocket = rocket;
                    if (!this.rocket.crud['u']) {
                        this.isEditable = false;
                    }
                    if (this.flightList) {
                        this.flightList.getFlights();
                    }
                    this.dataLoading.next(false);
                });
        }
    }
    
}
