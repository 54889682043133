import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

class User {
    email: string;
    password: string;
    name: string;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    @Input() user: User = {email:'',password:'',name:''}
    errMsg: BehaviorSubject<string> = new BehaviorSubject<string>('');
    doingLoginSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    returnUrl: string;
    verificationCode: string = null;
    
    constructor(private authService: AuthenticationService,
                private router: Router,
                private route: ActivatedRoute) { }

    ngOnInit() {
        console.log(this.route.snapshot.paramMap.get('verificationCode'));
        this.route.queryParams
            .subscribe(params => this.returnUrl = params['returnUrl'] || '/');
        if (this.route.snapshot.paramMap.get('verificationCode')) {
            this.verificationCode = this.route.snapshot.paramMap.get('verificationCode');
            this.onVerify();
        }
    }

    onVerify() {
        let me = this;
        this.doingLoginSubject.next(true);
        this.errMsg.next('');
        this.authService.verify(this.verificationCode)
            .pipe(
                catchError(e => {
                    if (e.error.error) {
                        this.errMsg.next(e.error.error);
                    } else {
                        this.errMsg.next('Unable to verify. Maybe your token has expired?');
                    }
                    return throwError(e);
                })
            )
            .subscribe({
                next(f) {
                    me.doingLoginSubject.next(false);
                    if (f && f.data && f.data.message) {
                        me.errMsg.next(f.data.message);
                    } else {
                        me.errMsg.next('Something might have happened');
                    }
                },
                error(f) {
                    me.doingLoginSubject.next(false);
                    me.errMsg.next('Error encountered while trying to validate email: '+(f.error.error?f.error.error:'Unknown error'));
                }
            });
    }

    onForgot() {
        let me = this;
        if (!this.user.email) {
            console.log('ignoring, no email set');
            return;
        }
        this.doingLoginSubject.next(true);
        this.errMsg.next('');
        this.authService.startPWReset(this.user.email)
            .subscribe(f => {
                me.doingLoginSubject.next(false);
                if (f && f.data && f.data.message) {
                    me.errMsg.next(f.data.message);
                } else {
                    me.errMsg.next('Something might have happened');
                }
            },
            error => {
                me.doingLoginSubject.next(false);
                me.errMsg.next('Error encountered while trying to send password reset email: '+(error.error.error.email?error.error.error.email:'Unknown error.'));
            });
    }
    
    onLogin() {
        let me = this;
        this.doingLoginSubject.next(true);
        this.errMsg.next('');
        this.authService.login(this.user.email, this.user.password)
            .pipe(
                catchError(e => {
                    if (e.error.error) {
                        this.errMsg.next(e.error.error);
                    } else {
                        this.errMsg.next('Unable to log in. Please verify your email address and password.');
                    }
                    return throwError(e);
                })
            )
            .subscribe({
                next(f) {
                    me.doingLoginSubject.next(false);
                    me.router.navigateByUrl(me.returnUrl);
                },
                error(f) { me.doingLoginSubject.next(false); }
            });
    }

    onSignup() {
        let me = this;
        this.doingLoginSubject.next(true);
        this.errMsg.next('');
        this.authService.register(this.user.name, this.user.email, this.user.password)
            .pipe(
                catchError(e => {
                    if (e.error.error) {
                        this.errMsg.next(e.error.error);
                    } else {
                        this.errMsg.next('Something went wrong during signup!.');
                    }
                    return throwError(e);
                })
            )
            .subscribe({
                next(f) {
                    me.doingLoginSubject.next(false);
                    if (f && f.data && f.data.message) {
                        me.errMsg.next(f.data.message);
                    } else {
                        me.errMsg.next('Check your email for a verification code!');
                    }
                },
                error(f) {
                    me.doingLoginSubject.next(false);
                    console.log(f);
                    me.errMsg.next('Error encountered during signup: '+(f.error.error.name?f.error.error.name:'Unknown error'));
                }
            });
    }
}
