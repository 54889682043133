export class Rocket {
    id: number;
    name: string;
    public: boolean;
    crud: object;
    designer: string;
    mass_g: number;
    created_at: Date;
    updated_at: Date;
    description: string;
    diameter_mm: number;
    length_mm: number;
    max_altitude_m: number;
    notes: string;
    user_id: number;
}
